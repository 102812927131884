<template>

<section id="lista-banners-muro-digital">
  <div class="row">
    <div 
      class="col-6 col-lg-4 col-xxl-3"
      v-for="(banner, i) in banners"
      :key="i"
    >
    <div class="card border-0 card-shadow card-item-add mb-3">
        <div class="card-body d-flex flex-column flex-xl-row">
          <div class="flex-shrink-0 text-center text-xl-start">
            <img 
              :src="banner.url_archivo"
              class="img-fluid img-obj rounded" 
              :class="forBannerH ? 'card-item-add-img-bannerH' : 'card-item-add-img-banner'"
            >
          </div>
          <div class="card-item-add-content d-flex flex-column flex-grow-1 mt-3 mt-xl-0 ms-0 ms-xl-3">
            <h5 class="card-item-add-content-title flex-grow-1 font-main color-gray">
              <font-awesome-icon icon="image" class="color-main pe-1"/>
              {{ banner.nombre_archivo | capitalize }}
            </h5>
            <div class="d-flex justify-content-end">
              <small class="bg-primary text-white rounded px-2"> 
                {{ banner.fecha_creacion | fechaFormat }}
              </small>
            </div>
          </div>
        </div>
        <div class="card-item-add-config">
          <a 
            href="javascript:" 
            @click="viewDetail(banner)"
            class="card-item-add-config-btn"
            data-bs-toggle="modal" 
            data-bs-target="#modal-detail-banner"
            content="Ver banner" 
            v-tippy="{ arrow: true }"
          >
            <font-awesome-icon icon="eye"/>
          </a>
          <a 
            href="javascript:" 
            @click="addBanner(banner)"
            class="card-item-add-config-btn"
            content="Seleccionar banner" 
            v-tippy="{ arrow: true }"
          >
            <font-awesome-icon icon="check"/>
          </a>
          <a 
            href="javascript:" 
            @click="openQuestionDelete(banner)"
            class="card-item-add-config-btn"
            content="Eliminar banner"
            v-tippy="{ arrow: true }" 
          >
            <font-awesome-icon icon="trash-alt"/>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <paginate
        v-model="pagination.actual_page"
        :page-count="pagination.page_count"
        :page-range="pagination.page_range"
        :click-handler="pagination.click_handler"
        :prev-text="pagination.prev_text"
        :next-text="pagination.next_text"
        :container-class="pagination.container_class"
        :page-class="pagination.page_class"
      />
    </div>
  </div>

  <ListaReproduccion
    :selected_items="selected_banners"
    thumbnailField="url_archivo"
    @deleteItemFromList="deleteBannerFromList"
  />
  
  <!-- Modal -->
  <div 
    class="modal modal-custom fade" 
    id="modal-detail-banner" 
    tabindex="-1" 
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">
            <font-awesome-icon icon="image" class="color-main pe-2"/>
            <span> {{ bannerDetail.nombre_archivo }} </span>
          </h1>
          <button 
            type="button" 
            class="modal-custom-close"
            data-bs-dismiss="modal" 
            aria-label="Close">
            <font-awesome-icon icon="xmark"/>
          </button>
        </div>
        <div class="modal-body text-center">
          <img 
            class="img-fluid" 
            :src="bannerDetail.url_archivo"
          />
          <div class="alert alert-light border text-center mt-3 mb-0">
            {{ bannerDetail.fecha_creacion | fechaFormat }}
          </div>
        </div>
        <div class="modal-footer">
          <button 
            type="button" 
            ref="closeModal"
            class="btn btn-custom-color-white rounded-pill mw-100" 
            data-bs-dismiss="modal">
            <i class="fa-solid fa-angle-left pe-1"></i>
            Volver
          </button>
          <button 
            type="button" 
            class="btn btn-custom-color-blue rounded-pill mw-100"
            @click="addBanner(bannerDetail)">
            Añadir
          </button>
        </div>
      </div>
    </div>
  </div>

  <Question
    v-if="open_question_modal"
    :msg="question_modal_msg"
    :hideCancel="false"
    @cancel="cancelQuestion"
    @accept="acceptQuestion"
  />
  <Spinner v-if="show_spinner" />
</section>


</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from "vuex";
import Question from "../Modales/Question.vue";
import Spinner from "../Spinner.vue";
import ListaReproduccion from "./ListaReproduccion.vue";

export default {
  components: { Question, Spinner, ListaReproduccion },

  props: {
    pagination: {
      type: Object
    },
    banners: {
      type: Array
    }
  },

  filters: {
    fechaFormat(fecha) {
      if (!fecha) return '';
      return moment(fecha).format('DD/MM/YYYY');
    },
    capitalize: function (value) {
      if (!value) return '';
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },

  data() {
    return {
      forBannerH: this.$route.name === "CanalCorporativo-listadoBannersHorizontal",
      selected_banners: [],
      view_detail: false,
      bannerDetail: {},
      show_spinner: false,
      open_question_modal: false,
      banner_to_delete: null,
      question_modal_msg: "¿Estás seguro(a) de eliminar este banner?"
    };
  },

  computed: {
    ...mapState("tvCorporativaModule", ["channelLayout"]),
  },

  watch: {
    selected_banners() {
      this.$emit("playlistChange", this.selected_banners);
    },
  },
  methods: {
    ...mapActions("tvCorporativaModule", ["getChannelStateFromLS", "deleteFileRepositorio"]),

    setBannerPlaylistFromState() {
      this.getChannelStateFromLS();
      if (this.forBannerH) {
        this.selected_banners = this.channelLayout.bannersH;
      } else {
        this.selected_banners = this.channelLayout.banners;
      }
    },
    viewDetail(banner) {
      this.view_detail = true;
      this.bannerDetail = banner;
    },
    closeDetail() {
      this.view_detail = false;
    },
    addBanner(data_) {
      if (this.view_detail == true) {
        this.view_detail = false;
      }
      this.selected_banners.push(data_);
      this.$refs.closeModal.click();
    },
    deleteBannerFromList(index) {
      this.selected_banners.splice(index, 1);
    },

    async deleteBanner(banner) {
      this.show_spinner = true;
      const data = {
        type:'2',
        id_archivo: +banner.id_archivo
      };
      const deletedFileObj = await this.deleteFileRepositorio(data);
      this.show_spinner = false;
      this.open_question_modal = false;
      if (!deletedFileObj) {
        this.toastBannerDeleteFalla();
      } else {
        if (deletedFileObj.nombre_archivo.includes("in use")) {
          this.toastBannerDeleteEnUso(deletedFileObj.canales);
        } else {            
          this.toastBannerDeleteExitoso();
          this.$emit("bannerDeleted");
        }
      }
    },

    openQuestionDelete(video) {
      this.banner_to_delete = video;
      this.open_question_modal = true;
    },

    async acceptQuestion() {
      await this.deleteBanner(this.banner_to_delete);
    },

    cancelQuestion() {
      this.open_question_modal = false;
    },

    toastBannerDeleteEnUso(canales) {
			this.$toast.open({
				message: `No es posible eliminar este banner, debes removerlo de los siguientes canales para continuar:<br/> <ul>${canales.map((txt)=>`<li> &bullet; ${txt}</li>`).join("")}</ul> `,
				type: "error",
				duration: 6000,
				position: "top-right",
			});
    },
    toastBannerDeleteFalla() {
			this.$toast.open({
				message: "Falla al momento de eliminar banner.",
				type: "error",
				duration: 6000,
				position: "top-right",
			});
    },
    toastBannerDeleteExitoso() {
			this.$toast.open({
				message: "El banner ha sido eliminado exitosamente.",
				type: "success",
				duration: 6000,
				position: "top-right",
			});
    }
  },
  mounted() {
    this.setBannerPlaylistFromState();
  },
};
</script>

